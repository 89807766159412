import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import axios from 'axios';
import './assets/styles/custom-style.scss';
import 'bootstrap/dist/js/bootstrap.bundle.js';
// import all fonts
import './assets/fonts/SourceSansPro/SourceSansPro-Black.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-Bold.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-Light.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-Regular.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff';
import './assets/fonts/SourceSansPro/SourceSansPro-Black.woff2';
import './assets/fonts/SourceSansPro/SourceSansPro-Bold.woff2';
import './assets/fonts/SourceSansPro/SourceSansPro-ExtraLight.woff2';
import './assets/fonts/SourceSansPro/SourceSansPro-Light.woff2';
import './assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2';
import './assets/fonts/SourceSansPro/SourceSansPro-SemiBold.woff2';

import './assets/fonts/CinzelDecorative/CinzelDecorative-Regular.woff';
import './assets/fonts/CinzelDecorative/CinzelDecorative-Regular.woff2';

import './assets/fonts/vr-iconfont/vr-iconfont.eot';
import './assets/fonts/vr-iconfont/vr-iconfont.svg';
import './assets/fonts/vr-iconfont/vr-iconfont.ttf';
import './assets/fonts/vr-iconfont/vr-iconfont.woff';
import './assets/fonts/vr-iconfont/vr-iconfont.woff2';

// import css
import './index.css';

import reportWebVitals from './reportWebVitals';

// import routes
import createRoutes from './hooks/createRoutes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

//change in prod/dev
axios.defaults.baseURL = 'https://crm.vetradiologie.app/api';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const router = createBrowserRouter(createRoutes());

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient} >
    <RouterProvider router={router} />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

